import { createSelector } from 'reselect';

export const CardsStore = state => state.cards;

// selectors using createSelector for memoization
export const cardsLength = createSelector(
    [CardsStore],
    (cardsState) => cardsState.cards.length
);

export const isAnyCardSelected = createSelector(
    [CardsStore],
    (cardsState) => cardsState.selectedCards.length > 0
);
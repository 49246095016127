import { useSelector } from 'react-redux';

import { cardsLength, isAnyCardSelected } from './createSelectors';

export const useCardsSelectors = () => {
    const {
        cards,
        selectedCards,
        statusMessage,
        existingGroups,
        conditionActive,
        recentActions,
        undoedActions,
        recentCards,

        localeDataCol,

        addingStepsOn,
        editingStepsOn,
        addingSubstepsOn,

        linkedLocalDataId,
        currentTestcaseName,
        sessionIdForNewTC,

        isGroupingPossible,
        isLoopingPossible,
        isDeletingPossible,

        groupingSelectedCards,
        loopingSelectedCards,
        ifSelectedCards,

        exitingIfelse,
        screenshotStep,
        isExpandedStep,
        deletedStepId,
        customInstruction,
        isActionDisabled,

        labelOrdinalForCard,
        allTextsForLabelOrdinal,
        cloneGroup,

        localDataType5,

        hwActiveAction,
        hwModalForCard,
        hwCards,
        hwEditingStepOn,
        hwSelectedSuggestion,
        hwSelectedCard,
        hwLoadingCard,
        hwScreenshotRectangles,

        paused,
        pausing,
        disabled,
        recorderActive,
        stopButtonLoading,
        startButtonLoading,


    } = useSelector(state => state.cards);


    return {
        // states from reducer-------------------------
        cards,
        selectedCards,
        statusMessage,
        existingGroups,
        conditionActive,
        recentActions,
        undoedActions,
        recentCards,

        localeDataCol,

        addingStepsOn,
        editingStepsOn,
        addingSubstepsOn,

        linkedLocalDataId,
        currentTestcaseName,
        sessionIdForNewTC,

        isGroupingPossible,
        isLoopingPossible,
        isDeletingPossible,

        groupingSelectedCards,
        loopingSelectedCards,
        ifSelectedCards,

        exitingIfelse,
        screenshotStep,
        isExpandedStep,
        deletedStepId,
        customInstruction,
        isActionDisabled,

        labelOrdinalForCard,
        allTextsForLabelOrdinal,
        cloneGroup,

        localDataType5,

        hwActiveAction,
        hwModalForCard,
        hwCards,
        hwEditingStepOn,
        hwSelectedSuggestion,
        hwSelectedCard,
        hwLoadingCard,
        hwScreenshotRectangles,

        paused,
        pausing,
        disabled,
        recorderActive,
        stopButtonLoading,
        startButtonLoading,

        // ---------------------------------------------

        // states from reselect ------------------------
        cardsLength: useSelector(cardsLength),
        isAnyCardSelected: useSelector(isAnyCardSelected)
        // ---------------------------------------------

    };
};

